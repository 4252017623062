@import "@uppy/core/src/style.scss";
@import "@uppy/progress-bar/src/style.scss";
@import "@uppy/file-input/src/style.scss";

.preview {
  padding: 5px;
  margin: 1em 0;
  background-image: linear-gradient(45deg, #dedede 25%, transparent 25%),
    linear-gradient(-45deg, #dedede 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #dedede 75%),
    linear-gradient(-45deg, transparent 75%, #dedede 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  text-align: center;

  img {
    max-height: 100%;
    margin: auto;
  }
}
